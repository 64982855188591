@import "../../../variables";

.fanumberfield {
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: $field-height;
    margin-bottom: $field-margin-bottom;
    ::placeholder {
        color: $medium-grey;
    }
    label {
        margin-bottom: $field-margin-label-bottom;
        margin-right: auto;
        font-size: $text-form-label;
        font-weight: $font-weight-title;
        color: $anthracite;
    }
    div {
        display:flex;
        justify-content: flex-end;
    }
    input {
        display: inline-flex;
        box-sizing: border-box;
        border: 1px solid $medium-grey;
        border-radius: $field-border-radius;
        font-size: $text-form-value;
        width: 20%;
        min-width: 80px;
        padding: $field-padding;
    }
    button.left {
        margin-right: $field-hor-margin;
    }
    button.right {
        margin-left: $field-hor-margin;
    }
    .white {
        background: $white;
    }
    .grey {
        background: $dark-grey;
    }
}