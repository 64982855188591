@import "../../../variables";
.fatextfield {
    position: relative;
    width: 100%;
    height: $field-height;
    margin-bottom: $field-margin-bottom;
    .flex {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-bottom: $field-margin-label-bottom;
        span {
            display: flex;
            justify-content: flex-end;
        }
    }
    > div {
        display: flex;
        justify-content: flex-end;
    }
    label {
        justify-content: flex-start;
        color: $anthracite;
        font-size: $text-form-label;
        font-weight: $font-weight-title;
        margin-right:auto;
    }
    input {
        border: 1px solid $medium-grey;
        border-radius: $field-border-radius;
        box-sizing: border-box;
        width: 100%;
        padding: $field-padding;
        font-size: $text-form-value;
        &.error{
            border: 1px solid $orange;
        }
    }
    ::placeholder {
        color: $medium-grey;
    }
    .icon-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        transform: translateY(-100%);
        pointer-events: none;
        svg {
            width: $field-icon-width;
            height: $field-icon-height;
        }
    }
}