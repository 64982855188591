@import "../../variables";

.register-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    height: 300px;
    width: $auth-form-width;
    max-width: $common-max-width;
    margin: $auth-form-margin;
    padding: $auth-form-padding;
    box-sizing: border-box;
    border-radius: $common-border-radius;
    button {
      margin-top: $auth-form-button-margintop;
      align-self: flex-end;
    }
    .register-error {
      color: $red;
      font-size: $text-form-value;
      height: 30px;
    }
    .back-link {
      font-size: $text-small;
      text-decoration: underline;
      margin-top: -15px;
    }
  }
}

.cgu {
  overflow: -moz-scrollbars-vertical;
  overflow-y:scroll;
  overflow-x:hidden;
  height: 400px;
  text-align: justify;
  padding: 10px;
  border: 1px solid $medium-grey;
}

.code-picker {
  width: 10%;
  &.center {
    width: 10%;
  }
  @include md {
    width: 10%;
    &.center {
      width: 10%;
    }
  }
}
