@import "../../../variables";
.fatextfield {
    position: relative;
    width: 100%;
    height: $field-height;
    margin-bottom: $field-margin-bottom;
    label {
        justify-content: flex-start;
        margin-right: auto;
        color: $anthracite;
        font-size: $text-form-label;
        font-weight: $font-weight-title;
    }
    svg {
        position: absolute;
        right: 8px;
        top: 32px;
        width: $field-icon-width;
        height: $field-icon-height;
    }
    select, select[size="0"], select[size="1"] {
        border-radius: 0px;
        border-color: $medium-grey;
    }
    select {
        &:invalid {
            color: $medium-grey;
        }
        &:focus {
            color: $anthracite;
        }
        option:first-of-type {
            color: $medium-grey;
        }
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid $medium-grey;
        border-radius: $field-border-radius;
        box-sizing: border-box;
        font-size: $text-form-value;
        width: 100%;
        padding: $field-padding;
        &.error{
            border: 1px solid $orange;
        }
        background-image:
            linear-gradient(45deg, transparent 50%, gray 50%),
            linear-gradient(135deg, gray 50%, transparent 50%),
            linear-gradient(to right, #ccc, #ccc);
        background-position:
            calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px),
            calc(100% - 2.5em) 0.5em;
        background-size:
            5px 5px,
            5px 5px,
            1px 1.5em;
        background-repeat: no-repeat;
    }

    select.error {
        background-image: none;
    }
}

