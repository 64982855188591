@import "../../variables";

.modal-badge {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $medium-zindex;
    border-radius: $common-medium-border-radius;
    border: 1px solid orange;
    overflow: hidden;
    background: $white;
    width: 210px;
    height: 260px;
    max-width: 210px;
    max-height: 260px;
    padding: 10px;
    color: $black;
    visibility: visible;
    @include md {
        width: 30%;
    }
}

.modal-badge-header {
    text-align: center;
    color: $orange;
    font-weight: bold;
    font-size: $text-form-title;
    margin-bottom:0px;
}

.modal-badge-header-info {
    text-align: center;
    color: black;
    font-weight: normal;
    font-size: $text-small;
    margin-bottom:0px;
}

.modal-badge-body {
    font-size: $text-form-value;
    margin-bottom: 0px;
    text-align:center;
}

.modal-badge-footer {
    text-align: center;
    color: $orange;
    font-weight: bold;
    font-size: $text-form-value;
    margin-bottom:0px;
    a:link { color: $orange };
    a:visited { color: $orange };
    a:hover { color: $orange };
    a:active { color: $orange };
}
