@import "../../variables";

.meeting-form-content {
    display: inline !important;
    padding: 0 0 8px 0 !important;
}

form {
    height: 100%;
    .form-content {
        height: 100%;
        overflow: hidden;
        &.modal {
            height: auto;
        }
        .meeting-content {
            box-sizing: border-box;
            padding-top: 17px;
            padding-left: $content-padding-md;
            padding-right: $content-padding-md;
            padding-bottom: $common-small-padding-list;
            overflow-y: auto;
            @include md {
                overflow-y: unset;
                padding-bottom: 0;
            }
            &.overflow {
                height: calc(100% - #{$form-pagination-height});
                overflow-y: auto;
            }
            .meeting-flex {
                display: inline-flex;
                align-items: center;

            }
        }
        .meeting-content.full-width {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.result {
    .switch-control {
        width: 100%;
        text-align: right;
        padding-bottom: 30px;
    }
    .switch-label, .switch {
        vertical-align: middle;
    }
    .switch-label {
        margin-right: 20px;
        font-weight: bold;
        font-size: $text-form-value;
    }
    .contact-list {
        padding-bottom: 0;
        &.no-result {
            padding-bottom: $common-small-padding-list;
            margin-top: 50px;
            margin-bottom: 50px;
            text-align: center;
        }
    }
    .contact-item {
        cursor: pointer;
    }
}
.pagination {
    box-sizing: border-box;
    width: 100%;
    height: $form-pagination-height;
    padding-top: $content-padding-md;
    text-align: center;
    .pagination-bullet {
        display: inline-block;
        border-radius: 100%;
        background: $medium-grey;
        width: 4px;
        height: 4px;
        margin: 0 4px;
    }
    .pagination-bullet-active {
        background: $anthracite;
    }
    .title-container {
        display: flex;
        text-align:center;
        margin: 0 30px;
    }
    h2 {
        flex:1 0 auto;
        font-size: $text-form-title;
    }
}
.header-img {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:top;
    background-image: url('/img/meetingRoom.png');
    width: 100%;
    height: $meeting-detail-sheight;
    overflow: hidden;
    z-index: 5;
    @include md {
        height: $meeting-detail-mheight;
    }
    @include xxl {
        height: $meeting-detail-bheight;
    }
}
.modal {
    margin-top: $form-modal-magin-top;
    padding-top: 1px;
    border-top-left-radius: $common-border-radius;
    border-top-right-radius: $common-border-radius;
    background: $light-grey;
    .meeting-content {
        height: calc(100% - #{$form-pagination-height} - #{$meeting-detail-sheight}) !important;
        @include md {
            height: calc(100% - #{$form-pagination-height} - #{$meeting-detail-mheight}) !important;
        }
        @include xxl {
            height: calc(100% - #{$form-pagination-height} - #{$meeting-detail-bheight}) !important;
        }
    }
}
.hour-picker {
    width: 40%;
    &.center {
        width: 30%;
    }
    @include md {
        width: 30%;
        &.center {
            width: 40%;
        }
    }
}
.limited-width {
    max-width: $common-max-width;
    margin: auto;
    &.with-padding {
        max-width: $common-max-width + $content-padding-sm + $content-padding-sm;
    }
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
.next.right {
    margin-right: 30px;
}
.separator {
    display: inline-block;
    height: 2px;
    width: 14px;
    background: $medium-grey;
}
.error-label {
    color: $orange;
    font-size: $text-form-label;
}
.room-list-container {
    min-height: 600px;
    transition: margin 700ms;
    background: $light-grey;
    button {
        float: right;
        text-decoration: underline;
        color: $orange;
    }
}
.block {
    padding: $content-padding-xs $content-padding-sm $content-padding-md $content-padding-sm;
    position: relative;
}
.block.form {
    background: $dark-grey;
    max-width: $common-max-width;
    margin:auto;
}
.block.room-list-container.close {
    margin: -290px 0;
} 
.room-list {
    padding-bottom:$common-small-padding-list;
    margin-top: 20px;
    @include md {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 0;
        margin-right: -7px;
    }
}
.room-list-header {
    padding-bottom: 0px;
    margin-top: 20px;
    @include md {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 0;
        margin-right: -7px;
    }
}
.room-list-container {
    h3 {
        text-align: center;
        font-size: $text-form-value;
        margin: 15px 0 20px 0;
        font-weight: $font-weight-medium-title;
    }
}
.room-image-layer-container {
    visibility: hidden;
    position:absolute;
    width: 100%;
    text-align: center;
    svg {
        position: absolute;
        top: 30px;
        margin-left: -15px;
        @include md {
            top: 50px;
        }
    }
}
.room-image-layer {
    background: $orange;
    opacity: 0.5;
    height: $meeting-room-image-size;
    width: $meeting-room-image-size;
    border-radius: $meeting-room-border-radius;
    @include md {
        width: 100%;
        height: $meeting-room-height;
        border-radius: 0;
        border-top-left-radius: $meeting-room-border-radius;
        border-top-right-radius: $meeting-room-border-radius;
    }
}
.room-card {
    display: flex;
    position: relative;
    box-sizing: border-box;
    background-color: none;
    width: 100%;
    margin-bottom: 15px;
    @include md {
        display: inline-block;
        flex: 1 0 50%;
        border-radius: $meeting-room-border-radius;
        border: 1px solid $light-grey;
        box-shadow: $meeting-shadow;
        vertical-align: top;
        background-color: $white;
        max-width: 49%;
        margin-right: 0.5%;
        margin-left: 0.5%;
    }
    @include lg {
        flex: 1 0 33%;
        max-width: 32.3%;
    }
    @include xl {
        flex: 1 0 25%;
        max-width: 24%;
    }
    @include xxl {
        flex: 1 0 20%;
        max-width: 19%;
    }
    &.selected {
        @include md {
            border: 1px solid $orange
        }
        .room-image-layer-container {
            visibility: visible;
        }
        .room-info {
            border: 1px solid $orange;
            @include md {
                border: none; 
            }
        }
    }
}
.room-image, .room-image img, .room-info {
    border-radius: $meeting-room-border-radius;
    z-index: 2;
    cursor: pointer;
    @include md {
        border-radius: 0;
        border-top-left-radius: $meeting-room-border-radius;
        border-top-right-radius: $meeting-room-border-radius;
    }
}
.room-info {
    @include md {
        border-radius: 0;
        border-bottom-left-radius: $meeting-room-border-radius;
        border-bottom-right-radius: $meeting-room-border-radius;
    }
}
.room-image, .room-image img {
    height: $meeting-room-image-size;
    flex: 0 0 $meeting-room-image-size;
    position: relative;
    background-size: cover;
    @include md {
        flex: auto;
        width: 100%;
        height: $meeting-room-height;
    }
}
.room-info {
    box-shadow: $meeting-shadow;
    z-index: 1;
    width: 100%;
    height: 80px;
    padding-left: 40px;
    margin:5px 0 0 -22px;
    background-color: $white;
    @include md {
        width: 90%;
        height: auto;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin:0;
        box-shadow:none;
        text-align:left;
    }
    h4 {
        color: $orange;
        font-weight: $font-weight-title;
        font-size: 16px;
        margin: 13px 0 0 0;
        @include md {
            margin: 0;
            font-size: $text-form-title;
        }
    }
    .capacity {
        font-size: $text-small;
        color: $dark-blue-grey;
        margin: 1px 0 2px 0;
        line-height: 14px;
    }
    .other {
        font-size: 10px;
        font-weight: $font-weight-title;
        color: $dark-blue-grey;
        margin: 1px 0 20px 0;
        line-height: 15px;
        @include md {
            font-size: $text-form-label;
            margin-bottom: 0;
            line-height: 20px;
        }

    }
}

