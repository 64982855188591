@import "../../variables";
.attendees-list {
  width: 100%;
  padding-bottom: $common-small-padding-list;
  @include md {
    overflow: auto;
    height: calc(100vh - 50px - 200px - 130px - 100px - 100px) !important; //TODO in P3: To improve with variable when validated
  }
  @include xxl {
    height: calc(100vh - 50px - 300px - 130px - 100px - 100px) !important;
  }
}