@import "../../variables";

.modal-confirm {
    position: fixed;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    z-index: $medium-zindex;
    border-radius: $common-medium-border-radius;
    border: 1px solid $orange;
    overflow: auto;
    background: $white;
    width: 70%;
    max-height: 500px;
    padding: 20px;
    color: $black;
    @include md {
        width: 30%;
    }
}

.modal-confirm-header {
    text-align: center;
    color: $black;
    font-weight: bold;
    font-size: $text-form-title;
    margin-bottom:25px;
}

.modal-confirm-body {
    font-size: $text-form-value;
    margin-bottom: 20px;
    text-align:center;
    &.cgu {
        text-align:justify;
    }
}

.modal-confirm-answer {
    display: flex;
    justify-content: center;
    button {
        border-radius: $common-medium-border-radius;
        margin-right: 20px;
        padding: 10px;
        &.confirm {
            background: $orange;
            color: $white;
        }
        &.cancel {
            color: $orange;
            background: $white;
            border: 1px solid $orange;
        }
    }
}
