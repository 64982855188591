@import "../../variables";

.contacts-separator {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  color: $medium-grey;
  div {
    flex: 1;
    border-top: 1.5px solid $medium-grey;
    height: 1px;
    margin-left: 20px;
  }
}

.contact-main {
  position: fixed;
  top:0;
  left:0;
  display: none;
  z-index: $medium-zindex;
  height: 100%;
  width: 100%;
  background: $transparent-background;
  &.show {
    display: block;
  }
}
.contact-modal {
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: $common-medium-border-radius;
  background: $light-grey;
  width: 80%;
  height: auto;
  padding: $content-padding-md;
  @include md {
    width: 500px;
  } 

  h2 {
    font-size: $text-modal-title !important;
    font-weight: $font-weight-title;
    margin-bottom: 30px;
  }

  .contact-button {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    .cancel {
      text-decoration: underline;
      font-size: $text-small;
      cursor: pointer;
    }
  }
}

.contact-header h4 {
  text-align: center;
  color: $dark-blue-grey;
  font-weight: $font-weight-title;
  font-size: $text-form-value;
}
.contact-list {
  padding-bottom: $common-small-padding-list;
  @include md {
    padding-bottom: $common-padding-list;
  }
}
.contact-list.no-result {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-padding.contact .result {
  .big-button {
    position: fixed;
    top: 90%;  
    left: 50%; 
    transform: translateX(-50%);
    min-width: 205px;
    @include md {
      top: 90%;  
      left: 65%; 
      transform: translateX(-50%);
    }
  }
}
