@import "../../variables";

nav {
    position: fixed;
    z-index: $medium-zindex;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    background-color: $anthracite;
    width: 100%;
    height: $navbar-small-height;
    padding: $navbar-padding-vert 15px;
    @include md {
        height: $navbar-height;
        padding: $navbar-padding-vert 20px $navbar-padding-vert 65px;
        background-color: $white;
    }
    #logo {
        display: flex;
        width: 50%;
        height: 100%;
        @include md {
            width: 20%;    
        }    
        span {
            height: 100%;
            width: 100%;
            background-image: url("/logo-light.png");
            background-repeat:no-repeat;
            background-position-y: center;
            @include md {
                background-image: url("/logo-dark.png");
            }
        }
    }
    .nav-items {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 50%;
        margin: 0;
        margin-top:10px;
        padding: 0;
        text-transform: uppercase;
        @include md {
            width: 80%; 
            margin-top:0;   
        }
        .active {
            color: $orange !important;
        }
        li {
            margin-left: 20px;
            @include md {
                margin-left:40px;
            }
            &.padding {
                padding-top:0;
                @include md {
                    padding-top:6px;
                }
            }
            &.toppadding {
                padding-top:6px;
            }
        }
    }
}