@import "../variables";

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//Disable blue background on selection on mobile
input,
textarea,
button,
select,
a,div {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

#root {
  height: 100%;
}

h2 {
  color: $orange;
  size: 18px;
  font-weight: 600;
  font-size: 20px !important;
  margin: 10px 0;
}
.desktop-tab-only {
  display: none;
  @include xs {
    display: inline;
    font-size: 1.4vw;
  }
  @include md {
    font-size: 15px;
  }
}
.desktop-only {
  display: none;
  @include md {
    display: inline;
    font-size: 1.4vw;
  }
  @include lg {
    font-size: 15px;
  }
}

.mobile-only {
  @include md {
    display: none;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: pre-line;
  overflow: hidden;
  text-align:justify;
}


button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  box-sizing: initial;
}
@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px !important;
  }
}

.page {
  font-size: 0.8em;
  height: 100%;
  padding-top: 76px;
  box-sizing: border-box;
  @include md {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
  }
  .image-placeholder {
    background-image:url('https://picsum.photos/500/500');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    height: 100%;
    width: $flex-access-image-width;
    overflow: hidden;
    position: fixed;
    z-index: 5;
  }
  .content {
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    min-width: 0;
    @include md {
      padding: 40px $content-padding-md;
      display: inline;
      margin-left: $flex-access-image-width;
    }
    .content-padding {
      max-width: 800px;
      margin: 0;
      @include xl {
        margin:auto;
      }
      padding: 8px $content-padding-additional;
    }
  } 

  .card {
    position: relative;
    background-color: white;
    min-height: 40px;
    min-width: 40px;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 10px 0;
    -webkit-box-shadow: 0 10px 6px -6px $shadow;
    -moz-box-shadow: 0 10px 6px -6px $shadow;
    box-shadow: 0 10px 6px -6px $shadow;
  }

  .trash-icon-button {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    width: 17px;
    height: 17px;
    padding: 5px;
    border-radius: 5px;
    background-color: $light-red;
    img {
      width: 16px;
      margin: auto;
    }
  }

  .container {
    display: flex;
    &.row {
      flex-direction: row;
    }
    &.column {
      flex-direction: column;
    }
  }
}

.text-error {
  color: $red;
  font-size: $text-form-value;
  height: 30px;
}

// desktop only = "if the primary input mechanism of the device includes an accurate pointing device"
@media (pointer: fine){
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px $dark-blue-grey;
    border: solid 3px transparent;
  }
}