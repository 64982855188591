@import "../../variables";

.home-header {
  text-align: center;
  @include md {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: initial;
    width: 50%;
  }
  .meeting-number {
    color: $medium-grey;
  }
}

.meeting-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  padding-bottom:$common-padding-list;
  @include md {
    margin-top: 90px;
    padding-bottom:0px;
  }
  a {
    width: 100%;
  }
  .meeting-item {
    flex: 0 0 100%;
    box-sizing: border-box;
    min-height: $home-meetingcard-min-height;
    @include lg {
      flex: 0 0 48%;
    }
    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $content-padding-xsm $content-padding-sm;
      .title {
        max-width: 70%;
        font-size: small;
        color: $orange;
        font-weight: $font-weight-small-title;
      }
    }
    .card-content {
      display: flex;
      flex-direction: column;
      padding: $content-padding-xsm $content-padding-sm $content-padding-smd $content-padding-sm;
      .card-row {
        display: flex;
        flex-direction: row;
        margin: 2px 0;
        div {
          width: 50%;
          img {
            width: 20px;
            vertical-align: bottom;
          }
          span {
            margin-left: 10px;
            font-size: smaller;
            font-weight: $font-weight-small-title;
          }
        }
      }
    }
  }
}

.fixed-button {
  position: fixed;
  left: 50%;
  bottom: $common-main-button-bottom;
  z-index: $low-zindex;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: fit-content;
  @include md {
    transform: none;
    left: initial;
    position: absolute;
    right: $content-padding-md + $content-padding-additional;
    bottom: initial;
  }
  @include lg {
    transform: none;
    left: initial;
    margin-top: -35px;
    right: $content-padding-lg + $content-padding-additional;
  }
}