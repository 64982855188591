@import "../../variables";

.notification-container {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: $high-zindex;
  width: 90%;
  font-size: $text-form-value;
  @include md {
    width: auto;
  }
  .toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: toaster .7s;
    background-color: $white;
    border-radius: $common-border-radius;
    color: $anthracite;
    padding: 10px 15px;
    .notification-image {
      img {
        width: 30px;
        height: 30px;
      }
    }
    .notification-text {
      flex: 1;
      p {
        margin: 0 15px;
      }
    }
    button {
      width: 15px;
      height: 15px;
    }
  }
}

@keyframes toaster {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}