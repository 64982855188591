@import "../../../variables";

.fa-checkbox-container {
  position: relative;
  display: block;
  width: 100%;
  margin: 15px auto;
  label {
    position: relative;
    min-height: 28px;
    display: block;
    padding-left: 40px;
    margin-bottom: 0;
    font-size: $text-form-label;
    font-weight: 700;
    cursor: pointer;
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      box-sizing: border-box;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 4px;
      width: 17px;
      height: 17px;
      transition: transform 0.28s ease;
      border-radius: 3px;
      border: 1px solid $medium-grey;
    }
    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 4px;
      border-bottom: 1px solid $white;
      border-left: 1px solid $white;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 9px;
      left: 8px;
    }
    span button {
      margin-top:0px;
      color: $orange;
    }
  }
  input {
    display:none;
    width: auto;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    left: 0;
    margin:-20px;
    &:checked ~ label{
      &:before {
        border: 2px solid $orange;
        background-color: $orange;
      }
      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }
    &:focus + label::before {
      outline: 0;
    }
  }
  &.link label:before {
    margin: 5px 0 4px 0;
  }
  &.link label:after {
    left: 4px;
  }
}