@import "../../../variables";
.fadatefield {
    position: relative;
    width: 100%;
    height: $field-height;
    margin-bottom: $field-margin-bottom;
    .react-datepicker-popper {
        z-index: $high-zindex;
    }
    .flex {
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-bottom: $field-margin-label-bottom;
        span {
            display: flex;
            justify-content: flex-end;
        }
    }
    > div {
        display: flex;
        justify-content: flex-end;
    }
    label {
        margin-right: auto;
        justify-content: flex-start;
        font-size: $text-form-label;
        font-weight: $font-weight-title;
        color: $anthracite;
    }
    input {
        border: 1px solid $medium-grey;
        border-radius: $field-border-radius;
        box-sizing: border-box;
        width: 100%;
        padding: $field-padding;
        font-size: $text-form-value;
        &.error{
            border: 1px solid $orange;
        }
    }
    .icon-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transform: translateY(-85%);
        height: 100%;
        pointer-events: none;
        svg {
            padding-right: 10px;
            width: $field-icon-width;
            height: $field-icon-height;
        }
    }
    ::placeholder {
        color: $medium-grey;
    }
    .react-datepicker {
        .react-datepicker__navigation {
            height: 0;
            width: 0;
        }
        .react-datepicker__triangle {
            display: none;
        }
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
            border-radius: 0.3rem;
            background-color: $orange;
            color: $white;
        }
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
            border-radius: 0.3rem;
            background-color: $orange;
            color: $white;
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
            height: 30px;
            padding: 5px 10px;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: $orange;
            color: $white;
            font-weight: bold;
        }
    }
}
