@import "../../variables";

.svg-icon-white {
  fill: $white;
  &.changing {
    @include md {
      fill: $anthracite;
    }
  }
}

.svg-icon-orange {
  fill: $orange;
}

.qrcode {
  padding-top: 25px;
  margin: 20px;
}