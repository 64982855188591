@import "../../variables";

.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    height: 300px;
    width: $auth-form-width;
    max-width: $common-max-width;
    margin: $auth-form-margin;
    padding: $auth-form-padding;
    box-sizing: border-box;
    border-radius: $common-border-radius;
    button {
      margin-top: $auth-form-button-margintop;
      align-self: flex-end;
    }
    .login-error {
      color: $red;
      font-size: $text-form-value;
      height: 30px;
    }
    .reset-link {
      font-size: $text-small;
      text-decoration: underline;
      margin-top: -15px;
    }
  }
  .register-link {
    margin-top: $auth-form-link-margintop;
    color: $orange;
    text-decoration: underline;
  }
}