@import "../../variables";

button.icon-button {
  text-align: center;
}
.big-square {
  width: 42px;
  height: 42px;
  border-radius: 5px;
}
.medium-square {
  width: 32px;
  height: 32px;
  border-radius: 3px;
}

.small-square {
  width: 26px;
  height: 26px;
  border-radius: 3px;
}
.grey {
  background: $dark-grey;
}

.light-grey {
  background: $light-grey;
}

.light-red {
    background: $light-red;
}
.big-button {
  display: flex;
  align-items: center;
  background-color: $orange;
  padding: 10px 25px;
  border-radius: 50px;
  z-index: 10;
  &.outline {
    color: $orange;
    background-color: $white;
    border: 1px solid $orange;
    position: initial;
    bottom: initial;
  }
  div {
      color:$white;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      &.icon {
        margin-left: 10px;
        display: flex;
        height: 100%;
        align-items: center;
      }
  }
  .bottom-right {
      position: fixed;
      bottom: 45px;
      @include md {
        position: absolute;
        right: $content-padding-md;
        bottom: initial;
      }
      @include lg {
        margin-top: -35px;
        right: $content-padding-lg;
      }
    }
  }
button:disabled.big-button{
    background-color: $dark-grey;
    cursor: default;
    div {
        color: $orange;
    }
}
