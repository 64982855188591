@import "../../variables";

.contact-item {
  display: flex;
  flex-direction: row;
  border: 1px solid transparent;
  padding: $content-padding-xs $content-padding-sm;
  .contact-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px;
    .name {
      font-weight: $font-weight-small-title;
    }
    .company {
      font-size: smaller;
      margin: 3px 0 7px 0;
    }
    .address {
      font-size: smaller;
      color: $medium-grey;
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &.selected {
    border: 1px solid $orange;
  }
  &.horizontal {
    .contact-info {
     flex-direction: row;
     align-items: center;
     span {
         margin-right: 15px;
     }
     .company, .dot {
      margin-bottom: 0;
      margin-top: 0;
      font-size: smaller;
      color: $medium-grey;
     }
    }
  }
}
