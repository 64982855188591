//Auth
$auth-form-padding: 20px;
$auth-form-margin: 0;
$auth-form-width: 90%;
$auth-form-button-margintop: 10px;
$auth-form-link-margintop: 30px;
//Meeting
$home-meetingcard-min-height: 135px;
$meeting-detail-sheight: 130px;
$meeting-detail-mheight: 200px;
$meeting-detail-bheight: 300px;
$meeting-room-border-radius: 8px;
$meeting-room-height: 130px;
$meeting-room-image-size: 90px;
$meeting-shadow: 4px 4px 10px rgba(66, 103, 178, 0.1);
//Navbar
$navbar-height: 50px;
$navbar-small-height: 76px;
$navbar-padding-vert: 5px;
//Fields
$field-height: 62px;
$field-icon-width: 18px;
$field-icon-height: 18px;
$field-border-radius: 5px;
$field-padding: 12px 15px;
$field-margin-bottom: 20px;
$field-margin-label-bottom:5px;
$field-hor-margin: 10px;
//Common
$common-max-width: 800px;
$common-border-radius: 20px;
$common-medium-border-radius: 10px;
$common-small-border-radius: 10px;
$common-padding-list: 100px;
$common-small-padding-list: 100px;
$common-main-button-bottom: 45px;

$high-zindex: 1000;
$medium-zindex: 100;
$low-zindex: 10;

$flex-access-image-width: 30%;
$content-padding-xs: 10px;
$content-padding-xsm: 15px;
$content-padding-sm: 20px;
$content-padding-smd: 25px;
$content-padding-md: 30px;
$content-padding-lg: 90px;
$content-padding-additional: 20px;
$form-pagination-height: 100px;
$form-modal-magin-top: -14px;

$orange: #f35c2c;
$light-red: #ffedef;
$medium-grey: #bbc6ce;
$light-grey: #f8f8fa;
$dark-grey: #eef0f3;
$dark-blue-grey: #8C9093;
$anthracite: #38454D;
$white: #ffffff;
$black: #000000;
$shadow: rgba(66, 103, 178, 0.1);
$green: #a9c274;
$red: #ff4554;
$transparent-background: rgba(0, 0, 0, 0.6);

$screen-xs-min: 376px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 1024px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1600px;

$text-tiny: 6px;
$text-small: 10px;
$text-medium: 12px;
$text-form-label: 12px;
$text-form-value: 14px;
$text-form-title: 18px;
$text-modal-title: 24px;
$font-weight-small-title: 500;
$font-weight-medium-title: 600;
$font-weight-title: bold;

@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
