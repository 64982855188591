@import "../../variables";

.meeting-header {
  display: initial;
  margin-top: 30px;
  text-align: center;
  @include md {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: initial;
  }
}
.meetings-button-container {
  margin-top:20px;
  display: flex;
  justify-content: center;
  @include md {
    justify-content: flex-end;
  }  
}
.header-img {
  background-repeat:no-repeat;
  background-size:cover;
  background-position:top;
  background-image: url('/img/meetingRoom.png');
  overflow: hidden;
  z-index: 5;
  width: 100%;
  height: $meeting-detail-sheight;
  @include md {
      height: $meeting-detail-mheight;
  }
  @include xxl {
      height: $meeting-detail-bheight;
  }
}
.modal {
  margin-top: $form-modal-magin-top;
  padding-top: 1px;
  border-top-left-radius: $common-border-radius;
  border-top-right-radius: $common-border-radius;
  background: $light-grey;
  .meeting-content {
      height: calc(100% - #{$form-pagination-height} - #{$meeting-detail-sheight}) !important;
      @include md {
          height: calc(100% - #{$form-pagination-height} - #{$meeting-detail-mheight}) !important;
      }
      @include xxl {
          height: calc(100% - #{$form-pagination-height} - #{$meeting-detail-bheight}) !important;
      }
  }
}
.meeting-button, a.meeting-button {
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 1px solid $orange;
  background-color: $white;
  width: fit-content;
  padding: 5px 30px;
  color: $orange;
  margin-top:20px;
  margin-bottom:20px;
  @include md {
    margin-top:0;
    margin-bottom:0;
  }
  svg {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
}
.meeting-container {
  width: 100%;
  @include md {
    display: flex;
    flex-direction: row;
    margin: 50px 0 0 0;
  }
  .meeting-info-container {
    text-align: center;
    flex: 1;
    div {
      text-align: start;
    }
    .meeting-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: 15px 0;
      img {
        width: 25px;
      }
      .meeting-detail-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 10px;
        font-weight: $font-weight-small-title;
        span {
          padding: 2px 0;
        }
      }
    }
    .cancel-button {
      display: initial;
      @include md {
        display: flex
      }
      margin: 15px 0;
      color: $anthracite;
      border: 1px solid $anthracite;
      @include md {
        margin: 100px 0 15px 0;
      }
    }
  }
  .attendees-container {
    flex: 1;
    .attendees-number {
      font-weight: $font-weight-small-title;
      width: 100%;
      margin: 10px 0 5px 0;
    }
  }
}